import { fdrBlogCriteria } from 'components/util/criterias/blog.dmc';
import { fdrFullScreenHeroCriteria } from 'components/util/criterias/hero.dmc';
import { fdrMarketingRecommendation } from 'components/util/criterias/marketingRecommendation.dmc';
import {
  fdrMediaFull,
  fdrMediaPreview,
} from 'components/util/criterias/media.dmc';

export const fdrHomepageVars = {
  heroCriteria: fdrFullScreenHeroCriteria,
  mediaFull: fdrMediaFull,
  mediaPreview: fdrMediaPreview,
  blogCriteria: fdrBlogCriteria,
  fdrMarketing: fdrMarketingRecommendation,
  input: { pagination: { page: 0, perPage: 20 } },
};
